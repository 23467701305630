<template>
  <div class="login_box">
    <div class="container_box">
      <div class="avatar">
        <img class="avatar_img" src="/logo-fff.png">
        <div class="avatar_title">{{ title }}</div>
      </div>
      <div class="enterInfo">
        <div class="tips">录题系统</div>
        <div style="margin: 100px 0 50px 0;">请登录您的账户</div>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item v-if="!isPassword">
            <el-input
              :maxlength="limit.phoneLength"
              show-word-limit
              id="phone"
              v-model="form.phone"
              @keyup.enter.native="loginEvent"
              placeholder="请输入您的手机号" style="height:40px"
            />
            <el-input
              class="input_style"
              :maxlength="limit.smsLength"
              show-word-limit
              type="text"
              id="code"
              v-model="form.code"
              @keyup.enter.native="loginEvent"
              placeholder="请输入验证码" style="height:40px;margin-top:10px"
            />
            <div class="get_code" @click="sendUserSms" v-if="!status.sendSmsIng">获取验证码</div>
            <div class="get_code" v-else>{{ status.currentCounter }} s</div>
          </el-form-item>
          <el-form-item v-else>
            <el-input
              :maxlength="limit.accountLength"
              show-word-limit
              id="account"
              v-model="form.account"
              @keyup.enter.native="loginEvent"
              placeholder="请输入您的账号" style="height:40px"
            />
            <el-input
              :maxlength="limit.passwordLength"
              show-word-limit
              show-password
              type="text"
              id="password"
              v-model="form.password"
              @keyup.enter.native="loginEvent"
              placeholder="请输入密码" style="height:40px;margin-top:10px"
            />
          </el-form-item>
        </el-form>
        <el-button class="login" @click="loginEvent" :loading="loading">登录</el-button>
        <div class="change-text" @click="changeLoginType('password')" v-if="!isPassword">
          密码登录
        </div>
        <div class="change-text" @click="changeLoginType('code')" v-else>
          验证码登录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SETTING } from '@/config/setting'
import { checkApiData, NStore } from '@/utils/common.fun'
import { login, sendSms } from '@/api/login'

export default {
  data () {
    return {
      status: {
        sendSmsIng: false,
        counter: 60,
        currentCounter: 60
      },
      limit: {
        phoneLength: 11,
        smsLength: 6,
        accountLength: 20,
        passwordLength: 16
      },
      form: {
        phone: null,
        code: null,
        type: 'SMS',
        account: null,
        password: null
      },
      title: process.env.VUE_APP_SYSTEM_TITLE,
      isPassword: false,
      loading: false
    }
  },
  beforeRouteEnter (to, from, next) {
    if (NStore.get(SETTING.CACHE.ACCESS_TOKEN)) {
      return next({ name: 'Home' })
    }
    next()
  },
  methods: {
    // 验证码登录操作
    loginEvent () {
      let params = {}
      if (this.isPassword) {
        if (!this.form.account) {
          document.getElementById('account').focus()
          return this.$message({ type: 'error', message: '请确认账号输入是否正确' })
        }
        if (!this.form.password) {
          document.getElementById('password').focus()
          return this.$message({ type: 'error', message: '请确认密码输入是否正确' })
        }
        params = {
          type: 'PSD',
          account: this.form.account,
          code: this.form.password
        }
      } else {
        if (!this.form.phone || this.form.phone.length !== this.limit.phoneLength) {
          document.getElementById('phone').focus()
          return this.$message({ type: 'error', message: '请确认手机号输入是否正确' })
        }
        if (!this.form.code || this.limit.smsLength !== this.form.code.length) {
          document.getElementById('code').focus()
          return this.$message({ type: 'error', message: '请确认验证码输入是否正确' })
        }
        params = {
          type: 'SMS',
          account: this.form.phone,
          code: this.form.code
        }
      }
      this.loading = true
      login(params).then(result => {
        if (result.code !== SETTING.NUMBER.ZERO) {
          return this.$message({ type: 'error', message: result.msg })
        }
        // 缓存登录信息
        this.$nStore.set(SETTING.CACHE.ACCESS_TOKEN, result.data.access_token, new Date().getTime() + 2592000 * 1000)
        this.$nStore.set(SETTING.CACHE.PROVINCE_ID, result.data.province_id, new Date().getTime() + 2592000 * 1000)
        this.$nStore.set(SETTING.CACHE.REFRESH_TOKEN, result.data.refresh_token, new Date().getTime() + 2592000 * 1000)
        this.$message({ type: 'success', message: '用户登录成功', duration: 1500 })
        if (this.isPassword) {
          this.$nStore.set(SETTING.CACHE.USER_PHONE, this.form.account)
        } else {
          this.$nStore.set(SETTING.CACHE.USER_PHONE, this.form.phone)
        }
        this.form = {}
        this.goHome()
      }).finally(() => {
        this.loading = false
      })
    },
    goHome () {
      localStorage.removeItem('num')
      this.$router.push({ name: 'Home' })
    },
    // 发送验证码
    sendUserSms () {
      if (this.form.phone != null && this.form.phone.length === this.limit.phoneLength) {
        sendSms(this.form).then(res => {
          if (checkApiData(res)) {
            this.$message({ type: 'success', message: '验证码发送成功' })
            this.status.sendSmsIng = true
            this.smsCounter()
          }
        }).catch(err => {
          this.$message({ type: 'error', message: err })
        })
      } else {
        document.getElementById('phone').focus()
        this.$message({ type: 'error', message: '请输入手机号' })
      }
    },
    // 计数
    smsCounter () {
      const timer = setInterval(() => {
        this.status.currentCounter--
        if (this.status.currentCounter <= 0) {
          clearInterval(timer)
          this.status.sendSmsIng = false
          this.status.currentCounter = 60
        }
      }, 1000)
    },
    changeLoginType (type) {
      if (type === 'password') {
        this.isPassword = true
      } else {
        this.isPassword = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login_box {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/login_bg.jpg');
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.container_box {
  width: 800px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-start;
  // position: relative;

  .avatar {
    width: 300px;
    height: 100%;
    background: #2C9B75;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // opacity: 0.1;
    background-color: rgba(30, 177, 128, 0.6);

    .avatar_img {
      width: 158px;
      height: 182px;
      margin-bottom: 24px;
      object-fit: contain;
    }

    .avatar_title {
      width: 216px;
      // height: 60px;
      line-height: 30px;
      text-align: center;
      font-size: 22px;
      color: #FFFFFF;
    }
  }

  .enterInfo {
    width: 500px;
    height: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 22px;
    color: #666666;
    position: relative;

    .tips {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 14px;
      width: 80px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      color: #1EB180;
      border-radius: 4px;
    }

    .el-input {
      width: 300px;
      height: 40px;
      margin-bottom: 20px;
    }

    .input_style {
      width: 182px;
      height: 40px;
    }

    .get_code {
      width: 108px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      border: 1px solid #1EB180;
      position: absolute;
      right: 100px;
      bottom: 19px;
      cursor: pointer;
      border-radius: 5px;
      background: #1EB180;
      color: white;
    }
  }

  .login {
    width: 300px;
    height: 40px;
    background-color: #1EB180;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
    position: absolute;
    bottom: 138px;
    cursor: pointer;
    border-radius: 5px;
  }
}

.change-text{
  font-size: 14px;
  color: #1EB180;
  cursor: pointer;
  position: absolute;
  bottom: 95px;
  left: 100px;
}

</style>
